<template>

</template>

<script>
import { createAuthManager, createAuthTokenManager } from "@/utils/di";
import { useRoute } from "vue-router";

export default {
  name: "Autologin",
  async created() {
    const route = useRoute();
    const token = route.query.token;
    const redirectTo = (route.query.redirect || "").toString();

    if (typeof token === "string") {
      try {
        const manager = createAuthManager();
        const bearerToken = await manager.autologin(token);
        createAuthTokenManager().create(bearerToken);
        sessionStorage.setItem("loggedIn", "autologin");
        return this.$router.replace(redirectTo || { name: "dashboard" });
      } catch (e) {
        console.error(e);
        return this.$router.replace({ name: "login" });
      }
    }
  }
};
</script>
